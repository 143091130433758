<template>
  <div
    class="idle-overlay"
  >
    <div class="idle-modal">
      <div class="idle-modal-inner">
        <h4
          class="idle-modal__title"
          v-html="'ACHTUNG'"
        ></h4>
        <div
          class="excerpt"
          v-html="`Sie werden in <strong>${time/1000}</strong> Sekunden abgemeldet!`"
        ></div>
        <div
          class="timer"
          v-html="time/1000"
        ></div>

        <div
          class="abort btn btn-default"
          v-html="'ABBRECHEN'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 30000,
    };
  },
  async created() {
    // get & set settings
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.time = this.settings.app.timeouts.terminalCountdown;
    }

    const timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        // logout user
        this.logoutUser();
        // clear interval
        clearInterval(timerId);
        // redirect to the welcome screen (screensaver)
        // this.$router.push('/');
        // redirect to home + reload app
        window.location.assign('/');
        // if (this.$route.name === 'index') {
        //   const query = { ...this.$route.query };
        //   delete query.start;
        //   this.$router.replace({ query });
        //   this.$router.go(0);
        // } else {
        //   this.$router.push('/');
        // }
      }
    }, 1000);
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    logoutUser() {
      // clear cart
      if (this.$store.state.storeCart.items) {
        this.$store.dispatch('clearCart');
      }

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      this.$http.post(`${process.env.VUE_APP_BASE_URL}/logout`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },
  },
};
</script>
